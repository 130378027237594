<template>
  <div class="w-full">
      <label class="vs-input--label" v-if="label">{{ label }}</label>
      <v-select
        v-model="student"
        @search="debouncedGetSearchStudent"
        @open="$emit('open-sigselect')"
        :clearable="false"
        :filterable="false"
        :reduce="(option) => option.value"
        :options="options"
        data-vv-as="Aluno"
        v-validate.initial="'required'"
        name="student"
        :placeholder="placeholder"
        :dir="$vs.rtl ? 'rtl' : 'ltr'"
        :disabled="disabled"
      >
        <template #no-options="{}"> Nenhum resultado encontrado. </template>
      </v-select>
      <span class="text-danger text-sm" v-show="errors.has('student')">{{
        errors.first('student')
      }}</span>
  </div>
</template>

<script>
// Store Module
import axios from '@/axios.js'
import _ from 'lodash'

export default {
  props: {
    value: {
      default () {
        return {
          id: null
        }
      }
    },
    disabled:{},
    multiple: {},
    useId: {// nao passar objeto, apenas id
      default: false
    },
    label: {
      default () {
        return 'Aluno'
      }
    },
    type_person:{
      default () {
        return 'STUDENT'
      }
    }
  },
  data () {
    return {
      placeholder: 'Digite CPF ou nome do aluno...',
      allOptions: [],
      allStudents: []
    }
  },
  computed: {
    student: {
      get () {
        if (this.useId) {
          return this.value
        }

        if (Array.isArray(this.value) && this.value.length) {
          return this.value.map(e => e.id)
        }
        return this.value && this.value.id ? this.value.id : null

      },
      set (value) {
        if (this.multiple) {
          this.$emit('input', this.allStudents.filter(e => value.includes(e.id)))
        } else {
          this.$emit('input', this.allStudents.find(e => e.id === value))
        }

      }
    },
    options () {
      if (this.allOptions.length) return this.allOptions
      if (Array.isArray(this.value) && this.value.length) return this.value.map(e => ({value: e.id, label: e.name}))
      if (this.value && this.value.id) return [{value: this.value.id, label: this.value.name}]
      return []
    }
  },
  methods: {
    fetchOptions (search, loading) {
      if (search.length) {
        loading(true)
        axios
          .post(`${process.env.VUE_APP_API_URL}/student/search`, {
            kw: search,
            type: this.type_person
          })
          .then(
            function (response) {
              this.allOptions = []
              this.allStudents = response.data
              for (const k in response.data) {
                this.allOptions.push({
                  value: response.data[k].id,
                  label: `${response.data[k].name} ${
                    response.data[k].cpf || ''
                  }`
                })
              }
              loading(false)
            }.bind(this)
          )
          .catch(function (error) {
            console.error(error)
          })
      } else {
        loading(false)
      }
    },
    isValid () {
      this.$validator.validateAll()
      return !this.errors.has('student')
    }
  },
  created () {
    this.debouncedGetSearchStudent = _.debounce(this.fetchOptions, 600)
  },
  mounted () {
    if (this.type_person === 'STUDENT') {
      this.placeholder = 'Digite CPF ou nome do aluno...'
    } else if (this.type_person === 'PROVIDER') {
      this.placeholder = 'Digite CPF/CNPJ ou nome do fornecedor...'
    }
    
  }
}
</script>
