var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "w-full" },
    [
      _vm.label
        ? _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _c("v-select", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate.initial",
            value: "required",
            expression: "'required'",
            modifiers: { initial: true },
          },
        ],
        attrs: {
          clearable: false,
          filterable: false,
          reduce: (option) => option.value,
          options: _vm.options,
          "data-vv-as": "Aluno",
          name: "student",
          placeholder: _vm.placeholder,
          dir: _vm.$vs.rtl ? "rtl" : "ltr",
          disabled: _vm.disabled,
        },
        on: {
          search: _vm.debouncedGetSearchStudent,
          open: function ($event) {
            return _vm.$emit("open-sigselect")
          },
        },
        scopedSlots: _vm._u([
          {
            key: "no-options",
            fn: function ({}) {
              return [_vm._v(" Nenhum resultado encontrado. ")]
            },
          },
        ]),
        model: {
          value: _vm.student,
          callback: function ($$v) {
            _vm.student = $$v
          },
          expression: "student",
        },
      }),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.errors.has("student"),
              expression: "errors.has('student')",
            },
          ],
          staticClass: "text-danger text-sm",
        },
        [_vm._v(_vm._s(_vm.errors.first("student")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }